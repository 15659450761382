<template>
  <v-container fluid>
    <div class="main">

      <v-overlay :value="overlay" absolute color="white" opacity="0.5">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn text @click="dialogSms = true">
            <v-icon left>mdi-cellphone-message</v-icon>
            SMS
          </v-btn>

          <v-btn
              :loading="ischeckLoading"
              color="gifty"
              dark
              depressed
              @click="[checkDatabase()]"
          >
            <v-icon left>mdi-refresh</v-icon>
            Verifier Gifty
          </v-btn>

          <v-btn
              color="primary"
              class="mx-2"
              depressed
              @click="[(credit = false), (uploadDialog = true)]"
          >
            <v-icon left>mdi-send-outline</v-icon>
            Envoyer solde
          </v-btn>

          <v-btn
              color="error"
              depressed
              @click="[(credit = true), (uploadDialog = true)]"
          >
            <v-icon left>mdi-send-outline</v-icon>
            Envoyer crédit
          </v-btn>

          <v-btn
              color="blue ml-2"
              dark
              depressed
              @click="[importUpdates()]"
              v-if="!in_array(['all', 'client_fournissseur'], $route.params.id)"
          >
            <v-icon left>mdi-refresh</v-icon>
            Importer des mises a jour
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isLoading">
        <v-col cols="3" v-for="i in 4" :key="i">
          <v-skeleton-loader type="article" class="shadow rounded-lg"/>
        </v-col>
      </v-row>
      <v-row cols="12" v-else>
        <v-col cols="3">
          <v-card class="shadow rounded-lg">
            <v-card-text>
              <v-row>
                <v-col cols="9">
                  Base de données
                  <h3 class="d-block font-weight-medium mt-2" v-if="database &&  database.database">
                    {{ database.database.name }}
                  </h3>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-avatar color="primary">
                    <v-icon dark>mdi-database-check</v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="shadow rounded-lg">
            <v-card-text>
              <v-row>
                <v-col cols="9">
                  Total Utilisateurs
                  <h3 class="d-block font-weight-medium mt-2">
                    {{ salepoints.total }}
                  </h3>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-avatar color="gifty">
                    <v-icon dark>mdi-office-building-marker</v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card class="shadow rounded-lg">
            <v-card-text>
              <v-row>
                <v-col cols="9">
                  Utilisateurs avec Gifty
                  <h3 class="d-block font-weight-medium mt-2" v-if="database">
                    {{ database.salepoints_gifty }}
                  </h3>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-avatar color="success">
                    <v-icon dark>mdi-check-circle</v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3">
          <v-card class="shadow rounded-lg">
            <v-card-text>
              <v-row>
                <v-col cols="9">
                  Total SMS
                  <h3 class="d-block font-weight-medium mt-2">
                    {{ messages }}
                  </h3>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-avatar color="info">
                    <v-icon dark>mdi-cellphone-message</v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <div>
                <v-text-field
                    v-model="keyword"
                    rounded
                    filled
                    dense
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                    clearable
                    placeholder="Rechercher..."
                />
              </div>
              <v-spacer/>

              <FilterDialog @filter="[filterBy($event)]"/>

              <v-btn
                  @click="openFormUser"
                  dense
                  small
                  class="gifty white--text rounded-lg text-none"
                  elevation="0"
                  v-if="$route.params.id != 'all'"
              >
                <v-icon class="white--text">mdi-plus</v-icon>
                Ajouter un Point
                de vente
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text v-if="isLoading">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-card-text>
            <v-card-text v-else>
              <div v-if="client_fournisseur">
                <div v-if="database.salepoints.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Stats</th>
                        <th>Nom</th>
                        <th>Téléphone</th>
                        <th>Email</th>
                        <th v-if="can('admin')">Solde Actuel</th>
                        <th>Categorie</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(item, i) in database.salepoints.data"
                          :key="i"
                      >
                        <td>
                          <v-tooltip
                              top
                              color="blue"
                              v-if="
                                in_array(
                                  [1],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  @click="showAccount(item.account.id)"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.account"
                              >
                                <v-icon> mdi-clipboard-text-clock</v-icon>
                              </v-btn>
                            </template>
                            <span>Historique Compte</span>
                          </v-tooltip>
                        </td>
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          {{ item.phone }}
                        </td>
                        <td>
                          {{ item.email }}
                        </td>
                        <td
                            class="text-no-wrap font-weight-medium gifty--text"
                            v-if="can('admin')"
                        >
                          {{
                            item.account
                                ? CurrencyFormatting(item.account.all_balance)
                                : CurrencyFormatting(0)
                          }}
                          DZD
                        </td>
                        <td>
                          {{ item.category ? item.category.name : "-" }}
                        </td>
                        <td>
                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="can(['admin'])"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.account != null"
                                  @click="balances(item.account.id)"
                              >
                                <v-icon>mdi-currency-usd</v-icon>
                              </v-btn>
                            </template>
                            <span>Soldes services</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1, 4],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="white"
                                  text
                                  elevation="0"
                                  class="mt-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    [
                                      (amountForm.name = item.name),
                                      (amountForm.id = item.account.id),
                                      (updateNameDialog = true),
                                    ]
                                  "
                              >
                                <v-icon color="primary" center
                                >mdi-send
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Envoyer un solde</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1, 4],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  class="mt-2"
                                  icon
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="commissions(item.id, item.name)"
                              >
                                <v-icon>mdi-sack-percent</v-icon>
                              </v-btn>
                            </template>
                            <span>Commissions</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  class="mt-2"
                                  icon
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="setServices(item.id, item.name)"
                              >
                                <v-icon>mdi-layers-triple</v-icon>
                              </v-btn>
                            </template>
                            <span>Services</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                        filled
                        v-model="filter.size"
                        style="width: 20px"
                        dense
                        :items="[10, 20, 30, 40, 50]"
                        @change="onPageChange"
                    ></v-select>
                    <v-spacer/>
                    <v-pagination
                        total-visible="6"
                        v-if="salepoints.total > filter.size"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(salepoints.total / filter.size)"
                        @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
              <div v-else>
                <div v-if="database.salepoints.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Stats</th>
                        <th>Nom Utilisateur</th>
                        <th v-if="$route.params.id === 'all'">Type</th>
                        <th>Propriétaire</th>
                        <th>Téléphone</th>
                        <th>Wilaya</th>
                        <th>Commune</th>
                        <th>Categorie</th>
                        <th>Gifty</th>
                        <th v-if="in_array(
                                [1, 4],
                                $store.getters.getUser.user.role.id
                              )">Solde Actuel
                        </th>

                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in database.salepoints.data"
                          :key="item.id"
                      >
                        <td>

                          <v-tooltip
                              top
                              color="blue"
                              v-if="
                                in_array(
                                  [1],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  @click="showAccount(item.account.id)"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.account"
                              >
                                <v-icon> mdi-clipboard-text-clock</v-icon>
                              </v-btn>
                            </template>
                            <span>Historique Compte</span>
                          </v-tooltip>
                        </td>
                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap"
                        >
                          {{ item.name }}
                        </td>
                        <td v-else class="text-no-wrap">
                          {{ item.pos_name }}
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap text-center"
                        >
                          <v-chip small>
                            {{ getTypeUser(item) }}
                          </v-chip>
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-center"
                        >
                            <span v-if="item.salepoint">
                              {{ item.salepoint.pos_name }}
                            </span>
                          <span v-else> / </span>
                        </td>
                        <td v-else class="text-no-wrap">
                          {{ item.owner_name }}
                        </td>
                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap"
                        >
                            <span v-if="item.salepoint">
                              {{ item.salepoint.owner_phone }}
                            </span>
                          <span v-else>
                              {{ item.phone }}
                            </span>
                        </td>
                        <td v-else class="text-no-wrap text-center">
                          {{ item.owner_phone }}
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap text-center"
                        >
                            <span v-if="item.salepoint">
                              {{
                                item.salepoint.wilaya != null
                                    ? item.salepoint.wilaya.name
                                    : "-"
                              }}
                            </span>
                          <span v-else>
                              {{ item.wilaya ? item.wilaya.name : "-" }}
                            </span>
                        </td>
                        <td v-else class="text-no-wrap">
                          {{ item.wilaya != null ? item.wilaya.name : "-" }}
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap text-center"
                        >
                            <span v-if="item.salepoint">
                              {{
                                item.salepoint.commune != null
                                    ? item.salepoint.commune.name
                                    : "-"
                              }}
                            </span>
                          <span v-else> / </span>
                        </td>
                        <td v-else class="text-no-wrap text-center">
                          {{ item.commune != null ? item.commune.name : "-" }}
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap text-center"
                        >
                            <span v-if="item.category">
                              {{ item.category.name }}
                            </span>
                        </td>


                        <td
                            v-else
                            class="font-weight-bold text-no-wrap text-center"
                        >
                          {{
                            item.account
                                ? item.account.user.category
                                    ? item.account.user.category.name
                                    : "-"
                                : "-"
                          }}
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap text-center"
                        >
                          <v-icon color="success" v-if="item.account != null"
                          >mdi-check-circle
                          </v-icon
                          >
                          <v-icon color="success" v-else
                          >mdi-circle-outline
                          </v-icon
                          >
                        </td>

                        <td v-else class="text-no-wrap text-center">
                          <v-icon color="success" v-if="item.account != null"
                          >mdi-check-circle
                          </v-icon>
                          <v-icon color="success" v-else
                          >mdi-circle-outline
                          </v-icon>
                        </td>

                        <td
                            class="font-weight-medium gifty--text text-no-wrap"
                            v-if="
                              $route.params.id !== 'all' &&
                              in_array(
                                [1, 4],
                                $store.getters.getUser.user.role.id
                              ) &&
                              item.account != null
                            "
                        >
                          {{
                            item.account
                                ? (can('admin') ? CurrencyFormatting(item.account.all_balance) : CurrencyFormatting(item.account.supplier_balance)) :
                                CurrencyFormatting(0)
                          }}


                          DZD
                        </td>

                        <td
                            class="font-weight-medium gifty--text text-no-wrap"
                            v-if="
                              $route.params.id === 'all' &&
                              in_array([1], $store.getters.getUser.user.role.id)
                            "
                        >
                            <span v-if="item.account != null">
                              {{ CurrencyFormatting(item.account.all_balance) }}
                              DZD
                            </span>
                        </td>

                        <td
                            v-if="$route.params.id === 'all'"
                            class="text-no-wrap"
                        >
                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1,4],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary"
                                     icon
                                     v-bind="attrs"
                                     v-on="on"
                                     v-if="item.account != null"
                                     @click="balances(item.account.id)"
                              >
                                <v-icon>mdi-currency-usd
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Soldes services</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1, 4],
                                  $store.getters.getUser.user.role.id
                                ) && item.account != null
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="primary"
                                  icon
                                  @click="
                                    [
                                      (amountForm.name = item.name),
                                      (amountForm.id = item.account.id),
                                      (updateNameDialog = true),
                                    ]
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon>mdi-send
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Envoyer un solde</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1, 4],
                                  $store.getters.getUser.user.role.id
                                ) && item.account != null
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="commissions(item.id, item.name)"
                              >
                                <v-icon>mdi-sack-percent</v-icon>
                              </v-btn>
                            </template>
                            <span>Commissions</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="setServices(item.id, item.name)"
                              >
                                <v-icon>mdi-layers-triple</v-icon>
                              </v-btn>
                            </template>
                            <span>Services</span>
                          </v-tooltip>
                        </td>
                        <td class="d-flex text-no-wrap" v-else>
                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1,4],
                                  $store.getters.getUser.user.role.id
                                )
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary"
                                     icon
                                     v-bind="attrs"
                                     v-on="on"
                                     v-if="item.account != null"
                                     @click="balances(item.account.id)"
                              >
                                <v-icon>mdi-currency-usd
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Soldes services</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1, 4],
                                  $store.getters.getUser.user.role.id
                                ) && item.account != null
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="primary"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    [
                                      (amountForm.name = item.pos_name),
                                      (amountForm.id = item.account.id),
                                      (updateNameDialog = true),
                                    ]
                                  "
                              >
                                <v-icon>mdi-send</v-icon>
                              </v-btn>
                            </template>
                            <span>Envoyer un solde</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1, 4],
                                  $store.getters.getUser.user.role.id
                                ) && item.account != null
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    commissions(
                                      item.account.user_id,
                                      item.pos_name
                                    )
                                  "
                              >
                                <v-icon>mdi-sack-percent</v-icon>
                              </v-btn>
                            </template>
                            <span>Commissions</span>
                          </v-tooltip>

                          <v-tooltip
                              bottom
                              color="primary"
                              v-if="
                                in_array(
                                  [1],
                                  $store.getters.getUser.user.role.id
                                ) && item.account != null
                              "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     color="primary"
                                     v-bind="attrs"
                                     v-on="on"
                                     @click="
                                    setServices(
                                      item.account.user_id,
                                      item.pos_name
                                    )
                                  "
                              >
                                <v-icon>mdi-layers-triple</v-icon>
                              </v-btn>
                            </template>
                            <span>Services</span>
                          </v-tooltip>

                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="primary"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="updateUser(item)"
                              >
                                <v-icon>mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Modifier</span>
                          </v-tooltip>

                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="primary"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="remove(item.id)"
                              >
                                <v-icon>mdi-delete-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Supprimer</span>
                          </v-tooltip>

                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary"
                                     icon
                                     v-bind="attrs"
                                     v-on="on"
                                     @click="sendSms(item)"
                              >
                                <v-icon>
                                  mdi-message-arrow-right
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Envoyer Un Sms</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                        filled
                        v-model="filter.size"
                        style="width: 20px"
                        dense
                        :items="[10, 20, 30, 40, 50]"
                        @change="onPageChange"
                    ></v-select>
                    <v-spacer/>
                    <v-pagination
                        total-visible="6"
                        v-if="salepoints.total > filter.size"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(salepoints.total / filter.size)"
                        @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <send-s-m-s-dialog :dialog-sms.sync="dialogSms" @reload="fetchData"/>

    <v-dialog v-model="uploadDialog" width="500" persistent>
      <form @submit.prevent="upload" enctype="multipart/form-data">
        <v-card>
          <v-card-title v-if="credit">
            Envoyer du crédit
            <v-spacer/>
            <v-btn
                icon
                @click="uploadDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-title v-else>
            Envoyer du solde
            <v-spacer/>
            <v-btn
                icon
                @click="uploadDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-8">

            <v-text-field outlined dense
                          label="Titre *"
                          @input="
                uploadErrors && uploadErrors.title
                  ? (uploadErrors.title = '')
                  : false
              "
                          :error-messages="uploadErrors.title"
                          v-model="uploadForm.title"
                          type="text"
            />

            <v-text-field outlined dense
                          label="Titre en arabe *"
                          @input="
                uploadErrors && uploadErrors.title_ar
                  ? (uploadErrors.title_ar = '')
                  : false
              "
                          :error-messages="uploadErrors.title_ar"
                          v-model="uploadForm.title_ar"
                          type="text"
            />

            <v-text-field outlined dense
                          v-if="credit"
                          label="Montant *"
                          @input="
                            uploadErrors && uploadErrors.name
                              ? (uploadErrors.name = '')
                              : false
                          "
                          :error-messages="uploadErrors.name"
                          v-model="uploadForm.amount"
                          type="number"
            />

            <v-file-input outlined dense
                          label="Fichier *"
                          show-size
                          suffix="Excel"
                          :hide-details="!credit ? 'auto' : ''"
                          @change="uploadFile"
                          @focus="
                uploadErrors && uploadErrors.excel_file
                  ? (uploadErrors.excel_file = '')
                  : false
              "
                          ref="file"
                          chips
                          :error-messages="uploadErrors.excel_file"
            />

            <div class="text-end mt-2 mb-5" v-if="!credit">
              <v-btn text color="gifty" class="text-none v-btn--active" @click="goTo()">
                <v-icon left>mdi-download</v-icon>
                Télécharger le modèle canva
              </v-btn>
            </div>


            <v-file-input outlined dense
                          v-if="!credit"
                          label="Image "
                          show-size
                          suffix="Image"
                          @change="uploadImage"
                          @focus="
                uploadErrors && uploadErrors.image
                  ? (uploadErrors.image = '')
                  : false
              "
                          ref="UploadImage"
                          chips
                          :error-messages="uploadErrors.image"
            />

            <v-textarea outlined dense
                        v-if="!credit"
                        label="Message "
                        @input="
                uploadErrors && uploadErrors.message
                  ? (uploadErrors.message = '')
                  : false
              "
                        :error-messages="uploadErrors.message"
                        v-model="uploadForm.message"
            />

          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :loading="btnUploadLoading"
                   depressed
                   type="submit"
            >
              <v-icon left>mdi-upload-outline</v-icon>
              Télécharger
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <v-dialog v-model="updateNameDialog" width="500" persistent>
      <form @submit.prevent="sendRefill">
        <v-card>
          <v-card-title>
            Envoie du solde
            <v-spacer/>
            <v-btn
                icon
                class="v-btn--active"
                color="primary"
                @click="updateNameDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <p class="text-center font-weight-bold pink--text darken-2">
              Compte : {{ amountForm.name }}
            </p>
            <v-alert
                border="left"
                color="error"
                dark
                v-if="sendAmountErrors.operation"
            >
              {{ sendAmountErrors.operation[0] }}
            </v-alert>

            <v-text-field
                class="mb-2"
                label="Titre *"
                v-model="amountForm.title"
                dense
                @input="
                sendAmountErrors && sendAmountErrors.title
                  ? (sendAmountErrors.title = '')
                  : false
              "
                :error-messages="sendAmountErrors.title"
            ></v-text-field>

            <v-text-field
                label="Titre arabe *"
                class="mb-2"
                v-model="amountForm.title_ar"
                dense
                @input="
                sendAmountErrors && sendAmountErrors.title_ar
                  ? (sendAmountErrors.title_ar = '')
                  : false
              "
                :error-messages="sendAmountErrors.title_ar"
            ></v-text-field>

            <v-text-field
                label="Montant *"
                v-model="amountForm.amount"
                dense
                @input="
                sendAmountErrors && sendAmountErrors.amount
                  ? (sendAmountErrors.amount = '')
                  : false
              "
                :error-messages="sendAmountErrors.amount"
            ></v-text-field>

            <v-btn
                v-for="amount in amounts"
                :key="amount"
                class="ma-2 font-weight-bold"
                style="cusror: pointer"
                check
                color="green"
                outlined
                rounded
                :disabled="amountForm.amount == amount"
                @click="setAmount(amount)"
            >
              {{ CurrencyFormatting(amount) }}
              <span small class="ml-1">DZD</span>
            </v-btn>

            <v-file-input
                label="Image "
                show-size
                suffix="Image"
                @change="uploadSoldeImage"
                @focus="
                sendAmountErrors && sendAmountErrors.image
                  ? (sendAmountErrors.image = '')
                  : false
              "
                ref="amountImage"
                chips
                :error-messages="sendAmountErrors.image"
            />

            <v-textarea
                label="Message "
                @input="
                sendAmountErrors && sendAmountErrors.message
                  ? (sendAmountErrors.message = '')
                  : false
              "
                :error-messages="sendAmountErrors.message"
                v-model="amountForm.message"
            />

            <v-checkbox
                v-model="refill_for_service"
                label="Un montant pour un service uniques"
            ></v-checkbox>

            <v-select
                class="mt-2"
                v-show="refill_for_service"
                prepend-icon="mdi-filter-outline"
                chips
                deletable-chips
                small-chips
                dense
                clearable
                :items="services.data"
                v-model="amountForm.service_id"
                :item-text="'name'"
                :item-value="'id'"
                label="Service..."
                hide-details
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                      :src="getImageLink(slotProps.item.image)"
                      max-height="25"
                      max-width="25"
                      width="25"
                      height="25"
                      class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>

            <v-select
                :disabled="!isAdmin"
                class="mt-3"
                :items="modes"
                label="Mode"
                :item-text="'label'"
                :item-value="'value'"
                v-model="amountForm.paied"
            ></v-select>

            <v-btn
                color="primary"
                :loading="btnUpdateLoading"
                depressed
                class="rounded-lg text-none mt-3"
                large
                type="submit"
            >
              <v-icon left>mdi-contant-save</v-icon>
              Enregistrer
            </v-btn>
          </v-card-text>
        </v-card>
      </form>
    </v-dialog>

    <UserServices ref="userServices"/>
    <AccountServiceBalances ref="accountBalances"/>
    <form-user ref="formUser" @refresh="fetchData()"/>
    <FormSms ref="formSms"/>
    <ImportUpdatesVue ref="importUpdates" @refresh="fetchData()"/>
  </v-container>
</template>
<script>
import {HTTP} from "@/http-common";
import SendSMSDialog from "@/views/database/dialogs/sendSMSDialog";

import FilterDialog from "./dialogs/FilterDialog.vue";
import UserServices from "./dialogs/UserServices";

import AccountServiceBalances from "./dialogs/AccountServiceBalances.vue";
import FormUser from "./dialogs/FormUser.vue";
import FormSms from "./dialogs/FormSms.vue";

import ImportUpdatesVue from "./dialogs/ImportUpdates.vue";

export default {
  components: {
    SendSMSDialog,
    FilterDialog,
    UserServices,
    AccountServiceBalances,
    FormUser,
    FormSms,
    ImportUpdatesVue,
  },
  data() {
    return {
      // database: {},
      set_paginate: 10,
      keyword: "",
      dialogSms: false,
      uploadDialog: false,
      updateNameDialog: false,
      btnUpdateLoading: false,
      uploadErrors: [],
      pagination: {
        current: 1,
        total: 0,
      },
      uploadForm: {
        title: "Transfert",
        title_ar: "تحويل",
        excel_file: "",
        amount: "",
        image: "",
        message: "",
      },

      amountForm: {
        id: 0,
        amount: 0,
        service_id: null,
        paied: 0,
        name: "",
        message: "",
        image: "",
        title: "Transfert",
        title_ar: "تحويل"
      },

      refill_for_service: false,

      credit: false,
      sendAmountErrors: {},
      btnUploadLoading: false,
      ischeckLoading: false,
      filter: {
        salepoint_type_ids: [],

        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
        page: 1,

        roles: [],
      },

      modes: [
        {
          value: 1,
          label: "Payé",
        },
        {
          value: 0,
          label: "Crédit",
        },
      ],

      roles: [
        {
          value: 2,
          label: "Client",
        },
        {
          value: 4,
          label: "Fournisseur",
        },
      ],

      amounts: [],

      overlay: false,
      id: null,
    };
  },

  methods: {
    goTo() {
      window.open(process.env.VUE_APP_FILE_URL + '/sold-canva.xlsx', "_blank");
    },
    showAccount(account_id) {
      let route = this.$router.resolve({path: "/users/" + account_id});
      window.open(route.href, "_blank");
    },

    fetchData() {
      this.filter.keyword = this.keyword;
      this.$store.dispatch("fetchSalepoints", {
        data: this.filter,
        id: this.$route.params.id,
      });
    },

    filterBy(value) {
      this.filter.selectedCommunes = value.selectedCommunes;
      this.filter.selectedWilayas = value.selectedWilayas;
      this.filter.has_gifty = value.has_gifty;
      this.filter.roles = value.roles;
      this.filter.categories = value.categories;

      this.fetchData();
    },

    upload() {
      if (this.credit) this.sendCredit();
      else this.sendSolde();
    },

    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },

    sendCredit() {
      this.btnUploadLoading = true;
      let formData = new FormData();
      Object.keys(this.uploadForm).forEach((key) => {
        formData.append(key, this.uploadForm[key]);
      });
      HTTP.post("/database/send-credit", formData)
          .then(() => {
            this.$successMessage = "Le credit a été envoyer avec succées";
            this.btnUploadLoading = false;
            this.uploadForm = {
              title: "Transfert",
              title_ar: "تحويل"
            };
            this.$refs.file.reset();
            this.$refs.uploadImage.reset();
            this.uploadDialog = false;
            this.fetchData();
            this.$store.dispatch("fetchAccount");
          })
          .catch((err) => {
            this.btnUploadLoading = false;
            this.uploadErrors = err.response.data.errors;
            console.log(err);
          });
    },

    sendSolde() {
      this.btnUploadLoading = true;
      let formData = new FormData();
      Object.keys(this.uploadForm).forEach((key) => {
        formData.append(key, this.uploadForm[key]);
      });
      HTTP.post("/database/send-sold", formData)
          .then(() => {
            this.$successMessage = "Le credit a été envoyer avec succées";
            this.btnUploadLoading = false;
            this.uploadForm = {};
            this.$refs.file.reset();
            this.$refs.uploadImage.reset();
            this.uploadDialog = false;
            this.fetchData();
            this.$store.dispatch("fetchAccount");
          })
          .catch((err) => {
            this.btnUploadLoading = false;
            this.uploadErrors = err.response.data.errors;
            console.log(err);
          });
    },

    sendRefill() {
      this.btnUpdateLoading = true;
      let formData = new FormData();
      formData.append("sender_account_id", this.user.user.account.id);
      formData.append("recipient_account_id", this.amountForm.id);
      formData.append("amount", this.amountForm.amount);
      formData.append("paied", this.amountForm.paied);
      formData.append("message", this.amountForm.message);
      formData.append("image", this.amountForm.image);
      formData.append("title", this.amountForm.title);
      formData.append("title_ar", this.amountForm.title_ar);

      var url = "/refill";

      if (this.amountForm.service_id && this.refill_for_service) {
        formData.append("service_id", this.amountForm.service_id);
        formData.append("paied", this.amountForm.paied);

        url = "/V2/refill";
      }

      HTTP.post(url, formData)
          .then(() => {
            this.$successMessage = "Le credit a été envoyer avec succées";
            this.btnUpdateLoading = false;

            this.amountForm = {
              id: 0,
              amount: 0,
              service_id: null,
              paied: this.$store.getters.getUser.user.role.id == 1 ? 1 : 0,
              name: "",
              message: "",
              image: "",
              title: "Transfert",
              title_ar: "تحويل"

            };


            this.updateNameDialog = false;
            this.$refs.amountImage.reset();
            this.fetchData();

            this.$store.dispatch("fetchAccount");
          })
          .catch((err) => {
            this.btnUpdateLoading = false;
            if (err.response && err.response.data.error == true) {
              this.sendAmountErrors = {
                operation: ["Erreur: impossible d'effectuer cette opération"],
              };
            } else {
              this.sendAmountErrors = err.response
                  ? err.response.data.errors
                  : {};
            }

            console.log(err);
          });
    },
    uploadFile(file) {
      this.uploadForm.excel_file = file;
    },
    uploadImage(image) {
      this.uploadForm.image = image;
    },
    uploadSoldeImage(image) {
      this.amountForm.image = image;
    },
    onPageChange() {
      this.fetchData();
    },

    filterRegion() {
      var numeric_array = [];
      for (var item in this.filter.selectedWilayas) {
        numeric_array.push(this.filter.selectedWilayas[item]);
      }
      console.log(numeric_array);
      this.$store.dispatch("fetchCommunes", {
        filter: numeric_array,
      });
    },
    resetFilter() {
      this.keyword = "";

      this.filter = {
        selectedCommunes: [],
        selectedWilayas: [],
        has_gifty: "all",
        period: [],
        startDate: "",
        endDate: "",
        keyword: "",
        size: 10,
      };
    },

    checkDatabase() {
      this.ischeckLoading = true;
      HTTP.post("/database/check/" + this.$route.params.id, {})
          .then(() => {
            this.fetchData();
            this.ischeckLoading = false;
          })
          .catch(() => {
            this.fetchData();
            this.ischeckLoading = false;
          });
    },

    commissions(user_id, name) {
      this.$router.push("/database/commissions/" + user_id + "/" + name);
    },

    setServices(user_id, name) {
      this.$refs.userServices.open(user_id, name);
    },

    setAmount(value) {
      this.amountForm.amount = value;
    },

    balances(account_id) {
      this.$refs.accountBalances.open(account_id);
    },

    openFormUser() {
      this.$refs.formUser.open(this.$route.params.id);
    },

    updateUser(item) {
      this.$refs.formUser.open(null, item);
    },

    sendSms(item) {
      this.$refs.formSms.open(item);
    },

    importUpdates() {
      this.$refs.importUpdates.open(this.$route.params.id);
    },

    getTypeUser(item) {
      if (item.role_id == 3) {
        if (item.salepoint) {
          return "Professionel";
        } else {
          return "Particulier";
        }
      } else {
        var type = "";
        var role = item.role_id;
        switch (role) {
          case 1:
            type = "ADMIN";
            break;
          case 2:
            type = "Client";
            break;
          case 4:
            type = "Fournisseur";
            break;
          case 5:
            type = "Recuperateur";
            break;
          case 6:
            type = "Store";
            break;
          case 7:
            type = "Support";
            break;
          case 8:
            type = "Order Manager";
            break;
        }
        return type;
      }
    },

    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
      this.$confirm_dialog_body = "Êtes-vous sûr de vouloir supprimer ce point de vente ?";
    },
    deleteItem() {
      this.overlay = true;
      let data = {
        id: this.id,
      };
      HTTP.post("/v1/database/delete-pos", data)
          .then(() => {
            this.overlay = false;
            this.fetchData();
            this.$successMessage = "Ce point de a  vente été supprimé avec succès.";
          })
          .catch((err) => {
            this.overlay = false;
            console.log(err);
          });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.getUser.user.role.id == 1;
    },
    client_fournisseur() {
      return "client_fournissseur" === this.$route.params.id;
    },
    isLoading() {
      return this.$store.getters.getLoading;
    },

    salepoints() {
      return this.$store.getters.getSalepoints;
    },

    database() {
      return this.$store.getters.getDatabase;
    },

    user() {
      return this.$store.getters.getUser;
    },
    messages() {
      return 0;
    },

    services() {
      return this.$store.getters.getServices;
    },

    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },
  },
  watch: {
    keyword() {
      this.fetchData();
    },
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
  },

  mounted() {
    for (let index = 1; index < 10; index++) {
      this.amounts.push(index * 10000);
    }

    this.amountForm.paied =
        this.$store.getters.getUser.user.role.id == 1 ? 1 : 0;

    this.fetchData();

    if (this.wilayas.length == 0) this.$store.dispatch("fetchwilayas");


    this.$store.dispatch("fetchServices", {
      filter: {
        for_form: true,

      },
    });
  },
};
</script>